<template>
  <div>
    <v-row>
      <v-col cols="12" sm="8" md="9">
        <px-circular-loading v-if="loading"/>
        <template v-if="!loading">
          <v-list-item v-for="produto in produtos" :key="produto.id">
            <v-list-item-avatar class="square-avatar">
              <px-oferta-foto max-height="40"
                              max-width="40"
                              class="d-flex align-center justify-center"
                              :foto="produto.oferta.foto"/>
            </v-list-item-avatar>
            <template v-if="!isMobile">
              <v-list-item-content>
                {{ produto.tituloProduto }}
                <div>
                  <px-show-variacoes-completo :variacoes="produto.variacoes"/>
                </div>
                <div>
                  <px-especificacao-oferta text-class="caption"
                                           :especificacao="produto.especificacaoProduto"/>
                </div>
              </v-list-item-content>
              <v-list-item-content>
                {{ produto.quantidade }} {{ produto.unidadeMedida.descricao }} x
                {{ produto.precoUnitario | currency }}
              </v-list-item-content>
              <v-list-item-content>
                {{ produto.total | currency }}
              </v-list-item-content>
            </template>
            <template v-else>
              <v-list-item-content>
                {{ produto.tituloProduto }}
                <div>
                  <px-show-variacoes-completo :variacoes="produto.variacoes"/>
                </div>
                <div>
                  {{ produto.quantidade }} {{ produto.unidadeMedida.descricao }} x
                  {{ produto.precoUnitario | currency }}
                </div>
                <div>
                  Total:
                  <span class="font-weight-bold">
                    {{ produto.total | currency }}
                  </span>
                </div>
              </v-list-item-content>
            </template>
          </v-list-item>
        </template>
      </v-col>
      <v-col cols="12" sm="4" md="3">
        <v-btn class="rounded-tag-small" block link :to="`compras/${compra.id}`">
          Ver compra
        </v-btn>
        <v-btn class="mt-3 rounded-tag-small" color="" block link :to="`compras/${compra.id}`">
          Enviar mensagem
        </v-btn>
        <px-compra-cancelar-dialog btn-class="mt-3 rounded-tag-small"
                                   v-if="!hideCancelado"
                                   :compra="compra"
                                   :small="false"
                                   block
                                   @movimentar="onMovimentar"/>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { SituacaoCompraMarketplace } from 'px-business-components';
import CompraService from './compras-service';
import CompraCancelarDialog from './detalhes/CompraCancelarDialog.vue';

export default {
  props: {
    compra: Object,
  },
  data() {
    return {
      loading: false,
      produtos: [],
    };
  },
  computed: {
    isMobile() {
      return this.$vuetify.breakpoint.xsOnly;
    },
    hideCancelado() {
      return this.compra.situacao === SituacaoCompraMarketplace.keys.CANCELADA.key
        || this.compra.situacao === SituacaoCompraMarketplace.keys.RECUSADA.key;
    },
  },
  methods: {
    getProdutosCompra() {
      this.loading = true;
      CompraService.getProdutosCompra(this.compra.id)
        .then(({ data }) => {
          this.produtos = data;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    onMovimentar() {
      this.$emit('change');
    },
  },
  created() {
    this.getProdutosCompra();
  },
  components: {
    pxCompraCancelarDialog: CompraCancelarDialog,
  },
};
</script>
